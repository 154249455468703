// src/pages/OrganizationSettings.js

import React, { useEffect, useState } from 'react';
import { Tabs, Tab, Box, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';

import OrganizationInfo from '../components/OrgTabs/OrganizationInfo';
import FoodSettings from '../components/OrgTabs/FoodSettings/FoodSettings';
import MachineSettings from '../components/OrgTabs/MachineSettings';
import FerrySettings from '../components/OrgTabs/FerrySettings';
import VeterinarianSettings from '../components/OrgTabs/VeterinarianSettings';
import BoxSettings from '../components/OrgTabs/BoxSettings';
import PricingSettings from '../components/OrgTabs/PricingSettings';
import UserManagement from '../components/OrgTabs/UserManagement';
import DocumentSettings from '../components/OrgTabs/DocumentSettings';
import TaxSettings from '../components/OrgTabs/TaxSettings';
import InventorySettings from '../components/OrgTabs/InventorySettings';
import TeacherSettings from '../components/OrgTabs/TeacherSettings/TeacherSettings';
import ServiceTypes from '../components/OrgTabs/ServiceOfferings/ServiceTypes';
import SmartThingsSettings from '../components/OrgTabs/SmartThingsSettings';
import LocationsSettings from '../components/OrgTabs/LocationsSettings';
import FarmDesigner from '../components/FarmDesigner/FarmDesigner';
import DeliverySettings from '../components/OrgTabs/DeliverySettings';

import { usePermissions } from '../context/PermissionsContext'; // Import PermissionsContext

const OrganizationSettings = () => {
  const { t } = useTranslation();
  const location = useLocation();
  const navigate = useNavigate();
  const query = new URLSearchParams(location.search);
  const { entitlements } = usePermissions(); // Get entitlements from PermissionsContext
  const [selectedTabId, setSelectedTabId] = useState('');

  // Extract enabled features from entitlements
  const features = React.useMemo(
    () =>
      entitlements
        .flatMap((entitlement) => entitlement.features)
        .filter((feature) => feature.status === 'enabled'),
    [entitlements]
  );

  const hasFeature = (featureName) => features.some((feature) => feature.name === featureName);
  const entitlement = entitlements.find(entitlement => entitlement.isActive) || {};
  const isEquistab = entitlement.internalName?.includes('equistab');
  const isErp = entitlement.internalName?.includes('erp');

  // Define all possible tabs with unique IDs
  const allTabs = [
    {
      id: 'organization-info',
      label: t('OrganizationSettings.Tabs.OrganizationInformation'),
      component: <OrganizationInfo />,
      requiredFeatures: [],
    },
    {
      id: 'food-settings',
      label: t('OrganizationSettings.Tabs.FoodSettings'),
      component: <FoodSettings />,
      requiredFeatures: ['food-management'],
    },
    {
      id: 'machine-settings',
      label: t('OrganizationSettings.Tabs.MachineSettings'),
      component: <MachineSettings />,
      requiredFeatures: ['machine-management'],
    },
    {
      id: 'ferry-settings',
      label: t('OrganizationSettings.Tabs.FerrySettings'),
      component: <FerrySettings />,
      requiredFeatures: ['ferry-management'],
    },
    {
      id: 'veterinarian-settings',
      label: t('OrganizationSettings.Tabs.VeterinarianSettings'),
      component: <VeterinarianSettings />,
      requiredFeatures: ['veterinarian-management'],
    },
    {
      id: 'box-settings',
      label: t('OrganizationSettings.Tabs.BoxSettings'),
      component: <BoxSettings />,
      requiredFeatures: ['box-management'],
    },
    {
      id: 'pricing-settings',
      label: t('OrganizationSettings.Tabs.PricingSettings'),
      component: <PricingSettings />,
      requiredFeatures: ['pricing-management'],
    },
    {
      id: 'user-management',
      label: t('OrganizationSettings.Tabs.UserManagement'),
      component: <UserManagement />,
      requiredFeatures: ['user-management'],
    },
    {
      id: 'document-settings',
      label: t('OrganizationSettings.Tabs.DocumentSettings'),
      component: <DocumentSettings />,
      requiredFeatures: ['document-management'],
    },
    {
      id: 'tax-settings',
      label: t('OrganizationSettings.Tabs.TaxSettings'),
      component: <TaxSettings />,
      requiredFeatures: ['tax-management'],
    },
    {
      id: 'inventory-settings',
      label: t('OrganizationSettings.Tabs.InventorySettings'),
      component: <InventorySettings />,
      requiredFeatures: ['inventory-management'],
    },
    {
      id: 'teacher-settings',
      label: t('OrganizationSettings.Tabs.TeacherSettings'),
      component: <TeacherSettings />,
      requiredFeatures: ['teacher-management'],
    },
    {
      id: 'service-types',
      label: t('OrganizationSettings.Tabs.ServiceTypes'),
      component: <ServiceTypes />,
      requiredFeatures: ['service-management'],
    },
    {
      id: 'smart-things-settings',
      label: t('OrganizationSettings.Tabs.SmartThingsSettings'),
      component: <SmartThingsSettings />,
      requiredFeatures: ['smart-things-management'],
    },
    {
      id: 'locations-settings',
      label: t('OrganizationSettings.Tabs.Locations'),
      component: <LocationsSettings />,
      requiredFeatures: ['location-management'],
    },
    {
      id: 'farm-layout',
      label: t('OrganizationSettings.Tabs.FarmLayout'),
      component: <FarmDesigner />,
      requiredFeatures: ['box-management'],
    },
    {
      id: 'delivery-settings',
      label: t('OrganizationSettings.Tabs.DeliverySettings'),
      component: <DeliverySettings />,
      requiredFeatures: ['delivery-management'],
    },
  ];

  // Filter tabs based on user permissions
  const availableTabs = allTabs.filter(
    (tab) => tab.requiredFeatures.length === 0 || tab.requiredFeatures.every(hasFeature)
  );

  // Function to validate and set the selected tab
  const validateAndSetTab = (tabId) => {
    const exists = availableTabs.some((tab) => tab.id === tabId);
    if (exists) {
      setSelectedTabId(tabId);
    } else {
      // If the tabId from URL doesn't exist, default to the first available tab
      const defaultTabId = availableTabs.length > 0 ? availableTabs[0].id : '';
      setSelectedTabId(defaultTabId);
      // Update the URL to reflect the default tab
      if (defaultTabId) {
        navigate(`${location.pathname}?tab=${defaultTabId}`, { replace: true });
      }
    }
  };

  // Initialize the selected tab based on URL or default
  useEffect(() => {
    const tabIdFromURL = query.get('tab');
    if (tabIdFromURL) {
      validateAndSetTab(tabIdFromURL);
    } else {
      // If no tab is specified in the URL, default to the first available tab
      const defaultTabId = availableTabs.length > 0 ? availableTabs[0].id : '';
      setSelectedTabId(defaultTabId);
      if (defaultTabId) {
        navigate(`${location.pathname}?tab=${defaultTabId}`, { replace: true });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [availableTabs, location.pathname]);

  // Update the selected tab when the URL changes
  useEffect(() => {
    const tabIdFromURL = query.get('tab');
    if (tabIdFromURL && tabIdFromURL !== selectedTabId) {
      validateAndSetTab(tabIdFromURL);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.search]);

  // Update the URL when the selected tab changes via user interaction
  const handleChange = (event, newTabId) => {
    if (newTabId !== selectedTabId) {
      navigate(`${location.pathname}?tab=${newTabId}`, { replace: true });
    }
  };

  // Find the selected tab component
  const selectedTab = availableTabs.find((tab) => tab.id === selectedTabId);

  return (
    <Box sx={{ width: '100%' }}>
      <Typography variant="h4" gutterBottom>
        {{ isEquistab } ? t('OrganizationSettings.EquistabSettings') : t('OrganizationSettings.ERPSettings') }
      </Typography>
      <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
        <Tabs
          value={selectedTabId}
          onChange={handleChange}
          aria-label="organization settings tabs"
          variant="scrollable"
          scrollButtons="auto"
        >
          {availableTabs.map((tab) => (
            <Tab key={tab.id} label={tab.label} value={tab.id} />
          ))}
        </Tabs>
      </Box>
      {selectedTab && (
        <Box sx={{ mt: 2 }}>
          {selectedTab.component}
        </Box>
      )}
    </Box>
  );
};

export default OrganizationSettings;
