import React, { useState, useEffect } from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions, TextField, Button, LinearProgress } from '@mui/material';
import { fetchDriverById, addDriver, updateDriver } from '../../services/DriverService';
import { useAuth } from '../../context/AuthContext';

const DriverDialog = ({ open, onClose, driverId = null, initialData = null, onSave }) => {
    const { apiKey, organizationId, token } = useAuth();
    const [driverData, setDriverData] = useState(initialData || { name: '', licenseNumber: '' });
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        if (driverId && !initialData) {
            loadDriverData(driverId);
        }
    }, [driverId, initialData]);

    const loadDriverData = async (id) => {
        setLoading(true);
        try {
            const data = await fetchDriverById(apiKey, organizationId, token, id);
            setDriverData(data);
        } catch (error) {
            console.error('Error fetching driver:', error);
        } finally {
            setLoading(false);
        }
    };

    const handleSave = async () => {
        setLoading(true);
        try {
            if (driverId) {
                await updateDriver(driverData, apiKey, organizationId, token);
            } else {
                await addDriver(driverData, apiKey, organizationId, token);
            }
            onSave(); // Callback to refresh the parent list or indicate save success
            onClose();
        } catch (error) {
            console.error('Error saving driver:', error);
        } finally {
            setLoading(false);
        }
    };

    return (
        <Dialog open={open} onClose={onClose} fullWidth>
            <DialogTitle>{driverId ? 'Edit Driver' : 'Add Driver'}</DialogTitle>
            {loading && <LinearProgress />}
            <DialogContent>
                <TextField
                    margin="normal"
                    fullWidth
                    label="Driver Name"
                    value={driverData.name}
                    onChange={(e) => setDriverData({ ...driverData, name: e.target.value })}
                    disabled={loading}
                />
                <TextField
                    margin="normal"
                    fullWidth
                    label="License Number"
                    value={driverData.licenseNumber}
                    onChange={(e) => setDriverData({ ...driverData, licenseNumber: e.target.value })}
                    disabled={loading}
                />
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose} disabled={loading}>Cancel</Button>
                <Button variant="contained" onClick={handleSave} disabled={loading}>
                    {driverId ? 'Save Changes' : 'Create Driver'}
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default DriverDialog;
