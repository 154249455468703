import React, { useState, useEffect } from 'react';
import { Box, Button, Typography } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import { useAuth } from '../../context/AuthContext';
import GenericTableView from '../GenericTableView';
import DriverDialog from '../DeliveryComponents/DriverDialog';
import VehicleDialog from '../DeliveryComponents/VehicleDialog';
import { fetchDrivers, deleteDriver } from '../../services/DriverService';
import { fetchVehicles, deleteVehicle } from '../../services/VehicleService';


const DeliverySettings = () => {
    const { apiKey, organizationId, token } = useAuth();
    const [drivers, setDrivers] = useState([]);
    const [vehicles, setVehicles] = useState([]);
    const [openDriverDialog, setOpenDriverDialog] = useState(false);
    const [openVehicleDialog, setOpenVehicleDialog] = useState(false);
    const [currentDriverId, setCurrentDriverId] = useState(null);
    const [currentVehicleId, setCurrentVehicleId] = useState(null);

    useEffect(() => {
        loadDrivers();
        loadVehicles();
    }, []);

    const loadDrivers = async () => {
        try {
            const data = await fetchDrivers(apiKey, organizationId, token);
            setDrivers(data);
        } catch (error) {
            console.error('Error fetching drivers:', error);
        }
    };

    const loadVehicles = async () => {
        try {
            const data = await fetchVehicles(apiKey, organizationId, token);
            setVehicles(data);
        } catch (error) {
            console.error('Error fetching vehicles:', error);
        }
    };

    const handleDeleteDriver = async (driverId) => {
        try {
            await deleteDriver(driverId, apiKey, organizationId, token);
            loadDrivers();
        } catch (error) {
            console.error('Error deleting driver:', error);
        }
    };

    const handleDeleteVehicle = async (vehicleId) => {
        try {
            await deleteVehicle(vehicleId, apiKey, organizationId, token);
            loadVehicles();
        } catch (error) {
            console.error('Error deleting vehicle:', error);
        }
    };

    const driverTableStructure = {
        headers: [
            { key: 'name', label: 'Name', display: { value: true } },
            { key: 'licenseNumber', label: 'License Number', display: { value: true } },
        ],
        rows: drivers.map((driver) => ({
            id: driver.id,
            name: { value: driver.name },
            licenseNumber: { value: driver.licenseNumber },
            actions: [
                {
                    label: 'Edit',
                    icon: <EditIcon />,
                    handler: () => {
                        setCurrentDriverId(driver.id);
                        setOpenDriverDialog(true);
                    },
                },
                {
                    label: 'Delete',
                    icon: <DeleteIcon />,
                    handler: () => handleDeleteDriver(driver.id),
                },
            ],
        })),
    };

    const vehicleTableStructure = {
        headers: [
            { key: 'make', label: 'Make', display: { value: true } },
            { key: 'model', label: 'Model', display: { value: true } },
            { key: 'name', label: 'Name', display: { value: true } },
            { key: 'licensePlate', label: 'License Plate', display: { value: true } },
            { key: 'capacity', label: 'Capacity', display: { value: true } },
        ],
        rows: vehicles.map((vehicle) => ({
            id: vehicle.id,
            make: { value: vehicle.make },
            model: { value: vehicle.model },
            name: { value: vehicle.name },
            licensePlate: { value: vehicle.licensePlate },
            capacity: { value: vehicle.capacity },
            actions: [
                {
                    label: 'Edit',
                    icon: <EditIcon />,
                    handler: () => {
                        setCurrentVehicleId(vehicle.id);
                        setOpenVehicleDialog(true);
                    },
                },
                {
                    label: 'Delete',
                    icon: <DeleteIcon />,
                    handler: () => handleDeleteVehicle(vehicle.id),
                },
            ],
        })),
    };

    return (
        <Box>
            <Box display="flex" justifyContent="space-between" alignItems="center" mb={2}>
                <Typography variant="h5">Manage Drivers</Typography>
                <Button variant="contained" startIcon={<AddIcon />} onClick={() => setOpenDriverDialog(true)}>
                    Add Driver
                </Button>
            </Box>
            <GenericTableView
                structure={driverTableStructure}
                onClick={(driverId) => {
                    setCurrentDriverId(driverId);
                    setOpenDriverDialog(true);
                }}
            />

            <Box display="flex" justifyContent="space-between" alignItems="center" mb={2} mt={4}>
                <Typography variant="h5">Manage Vehicles</Typography>
                <Button variant="contained" startIcon={<AddIcon />} onClick={() => setOpenVehicleDialog(true)}>
                    Add Vehicle
                </Button>
            </Box>
            <GenericTableView
                structure={vehicleTableStructure}
                onClick={(vehicleId) => {
                    setCurrentVehicleId(vehicleId);
                    setOpenVehicleDialog(true);
                }}
            />

            {/* Driver Dialog */}
            <DriverDialog
                open={openDriverDialog}
                onClose={() => setOpenDriverDialog(false)}
                driverId={currentDriverId}
                onSave={loadDrivers}
            />

            {/* Vehicle Dialog */}
            <VehicleDialog
                open={openVehicleDialog}
                onClose={() => setOpenVehicleDialog(false)}
                vehicleId={currentVehicleId}
                onSave={loadVehicles}
            />
        </Box>
    );
};

export default DeliverySettings;
