// RouteComponent.js (Updated)
import React, { useEffect, useState } from 'react';
import {
    Box,
    Typography,
    IconButton,
    FormControl,
    InputLabel,
    Select,
    MenuItem,
    Paper,
} from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';

import {
    SortableContext,
    verticalListSortingStrategy,
} from '@dnd-kit/sortable';
import { useDroppable, useDndContext } from '@dnd-kit/core';
import SortableSalesOrderItem from './SortableSalesOrderItem';

const RouteComponent = ({
    route,
    vehicles,
    drivers,
    salesOrders,
    items,
    onRouteChange,
    onRemoveRoute,
    handleRemoveOrder,
    activeId,
}) => {
    const [selectedVehicleId, setSelectedVehicleId] = useState(route.vehicleId);
    const [selectedDriverId, setSelectedDriverId] = useState(route.driverId);
    const [routeName, setRouteName] = useState(route.name || `Route ${route.id}`);

    const { active } = useDndContext(); // Use useDndContext to get active drag state
    const isDragging = Boolean(active);

    // Use useDroppable on the sales orders container
    const { isOver, setNodeRef } = useDroppable({
        id: route.id,
    });

    // Effect to set initial route name if vehicle and driver are already selected
    useEffect(() => {
        updateRouteName(selectedVehicleId, selectedDriverId);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handleVehicleChange = (event) => {
        const newVehicleId = event.target.value;
        setSelectedVehicleId(newVehicleId);
        updateRouteName(newVehicleId, selectedDriverId);
    };

    const handleDriverChange = (event) => {
        const newDriverId = event.target.value;
        setSelectedDriverId(newDriverId);
        updateRouteName(selectedVehicleId, newDriverId);
    };

    const updateRouteName = (vehicleId, driverId) => {
        const vehicle = vehicles.find(v => v.id === vehicleId);
        const driver = drivers.find(d => d.id === driverId);

        const vehicleName = vehicle ? vehicle.name : '';
        const driverName = driver ? driver.name : '';

        const newName =
            vehicle || driver
                ? `${vehicleName}${vehicle && driver ? ' - ' : ''}${driverName}`
                : `Route ${route.id}`;

        setRouteName(newName);

        // Update the route object with the new name
        onRouteChange({
            ...route,
            vehicleId: vehicleId,
            driverId: driverId,
            name: newName,
        });
    };

    // Map items to salesOrders in the order defined by items
    const routeSalesOrders = items
        .map((id) => salesOrders.find((order) => order.id === id))
        .filter((order) => order !== undefined);

    // Apply styles based on drag state
    const droppableStyle = {
        backgroundColor: isOver ? '#e0f7fa' : 'transparent',
        border: isOver ? '2px dashed #00bcd4' : '2px dashed #ccc',
    };

    return (
        <Paper
            sx={{
                p: 2,
                mt: 2,
                border: '1px solid #ccc',
                borderRadius: '4px',
            }}
        >
            <Box display="flex" justifyContent="space-between" alignItems="center">
                <Typography variant="h6">{routeName}</Typography>
                <IconButton onClick={() => onRemoveRoute(route.id)} aria-label="delete">
                    <DeleteIcon />
                </IconButton>
            </Box>

            <Box sx={{ mt: 2 }}>
                <FormControl fullWidth sx={{ mb: 2 }}>
                    <InputLabel>Vehicle</InputLabel>
                    <Select value={selectedVehicleId} label="Vehicle" onChange={handleVehicleChange}>
                        <MenuItem value="">
                            <em>None</em>
                        </MenuItem>
                        {vehicles.map((vehicle) => (
                            <MenuItem key={vehicle.id} value={vehicle.id}>
                                {vehicle.name}
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>

                <FormControl fullWidth sx={{ mb: 2 }}>
                    <InputLabel>Driver</InputLabel>
                    <Select value={selectedDriverId} label="Driver" onChange={handleDriverChange}>
                        <MenuItem value="">
                            <em>None</em>
                        </MenuItem>
                        {drivers.map((driver) => (
                            <MenuItem key={driver.id} value={driver.id}>
                                {driver.name}
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>

                {/* Sales Orders Drag and Drop List */}
                <Box sx={{ mt: 2 }}>
                    <Typography variant="subtitle1">Sales Orders</Typography>

                    {/* Apply useDroppable to the sales orders container */}
                    <Box
                        ref={setNodeRef}
                        sx={{
                            mt: 1,
                            minHeight: '50px',
                            padding: '8px',
                            borderRadius: '4px',
                            ...droppableStyle,
                        }}
                    >
                        <SortableContext items={items} strategy={verticalListSortingStrategy}>
                            {routeSalesOrders.length === 0 ? (
                                <Box
                                    sx={{
                                        p: 2,
                                        textAlign: 'center',
                                        color: '#aaa',
                                    }}
                                >
                                    {isDragging ? 'Drop sales orders here' : 'No sales orders assigned'}
                                </Box>
                            ) : (
                                routeSalesOrders.map((order) => (
                                    <SortableSalesOrderItem
                                        key={order.id}
                                        id={order.id}
                                        salesOrder={order}
                                        onRemove={() => handleRemoveOrder(order.id, route.id)}
                                    />
                                ))
                            )}
                        </SortableContext>
                    </Box>
                </Box>
            </Box>
        </Paper>
    );
};

export default RouteComponent;
