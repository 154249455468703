// src/pages/DeliveryPlanDetailsPage.js

import React, { useState, useEffect } from 'react';
import {
    Paper,
    Typography,
    Box,
    Divider,
    List,
    ListItemText,
    Button,
    Chip,
    LinearProgress,
    Accordion,
    AccordionSummary,
    AccordionDetails,
    ListItemButton, // Import ListItemButton
} from '@mui/material';
import { useParams, useNavigate } from 'react-router-dom';
import EditIcon from '@mui/icons-material/Edit';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import PrintIcon from '@mui/icons-material/Print';
import DeliveryPlanPrintDialog from './DeliveryPlanPrintDialog';
import SnackbarAlert from '../../components/SnackbarAlert'; // Import SnackbarAlert
import ConfirmationDialog from '../../components/ConfirmationDialog'; // Import ConfirmationDialog

import { useAuth } from '../../context/AuthContext';
import { fetchDeliveryPlanById } from '../../services/DeliveryPlanService';
import { fetchVehicles } from '../../services/VehicleService';
import { fetchDrivers } from '../../services/DriverService';
import { fetchSummarySalesOrders } from '../../services/SalesOrderService';
import { createDeliveryNotesForDeliveryPlan } from '../../services/DeliveryPlanService'; // Import the new service function

const DeliveryPlanDetailsPage = () => {
    const { id } = useParams();
    const navigate = useNavigate(); // Initialize useNavigate
    const { apiKey, organizationId, token: jwtToken } = useAuth();
    const [printDialogOpen, setPrintDialogOpen] = useState(false);

    const [deliveryPlan, setDeliveryPlan] = useState(null);
    const [vehicles, setVehicles] = useState([]);
    const [drivers, setDrivers] = useState([]);
    const [salesOrders, setSalesOrders] = useState([]);
    const [loading, setLoading] = useState(true);

    const [snackbar, setSnackbar] = useState({ open: false, message: '', severity: 'success' });
    const [confirmationDialog, setConfirmationDialog] = useState({ open: false, text: '' });

    useEffect(() => {
        const loadData = async () => {
            setLoading(true);
            try {
                // Fetch delivery plan
                const planData = await fetchDeliveryPlanById(apiKey, organizationId, jwtToken, id);
                setDeliveryPlan(planData);

                // Fetch vehicles, drivers, and sales orders
                const [vehiclesData, driversData, salesOrdersData] = await Promise.all([
                    fetchVehicles(apiKey, organizationId, jwtToken),
                    fetchDrivers(apiKey, organizationId, jwtToken),
                    fetchSummarySalesOrders(apiKey, organizationId, jwtToken),
                ]);

                setVehicles(vehiclesData);
                setDrivers(driversData);
                setSalesOrders(salesOrdersData);
            } catch (error) {
                console.error('Error loading data:', error);
                setSnackbar({ open: true, message: `Error loading data: ${error.message}`, severity: 'error' });
            } finally {
                setLoading(false);
            }
        };

        loadData();
    }, [id, apiKey, organizationId, jwtToken]);

    const handleEdit = () => {
        navigate(`/delivery-plans/edit/${id}`);
    };

    const handlePrint = () => {
        setPrintDialogOpen(true);
    };

    const handleCreateDeliveryNotes = () => {
        setConfirmationDialog({
            open: true,
            text: 'Are you sure you want to create delivery notes for all sales orders in this delivery plan?',
        });
    };

    const confirmCreateDeliveryNotes = async () => {
        setConfirmationDialog({ ...confirmationDialog, open: false });
        try {
            await createDeliveryNotesForDeliveryPlan(apiKey, organizationId, jwtToken, id);
            setSnackbar({ open: true, message: 'Delivery notes created successfully.', severity: 'success' });
        } catch (error) {
            console.error('Error creating delivery notes:', error);
            setSnackbar({ open: true, message: `Error: ${error.message}`, severity: 'error' });
        }
    };

    const handleCloseSnackbar = () => {
        setSnackbar({ ...snackbar, open: false });
    };

    const handleCloseConfirmationDialog = () => {
        setConfirmationDialog({ ...confirmationDialog, open: false });
    };

    if (loading) {
        return (
            <Box
                display="flex"
                flexDirection="column"
                alignItems="center"
                minHeight="100vh"
            >
                <Typography variant="h6">Loading delivery plan data...</Typography>
                <LinearProgress sx={{ width: '100%', mt: 2 }} />
            </Box>
        );
    }

    if (!deliveryPlan) {
        return (
            <Typography variant="h6">Delivery plan not found.</Typography>
        );
    }

    return (
        <Paper elevation={3} sx={{ p: 2, mt: 2 }}>
            <Box display="flex" justifyContent="space-between" alignItems="center" mb={2}>
                <Typography variant="h4" gutterBottom>
                    Delivery Plan Details
                </Typography>
                <Box display="flex" alignItems="center">
                    <Button
                        variant="contained"
                        color="primary"
                        startIcon={<PrintIcon />}
                        onClick={handlePrint}
                        sx={{ mr: 2 }}
                    >
                        Print
                    </Button>
                    <Button
                        variant="contained"
                        color="primary"
                        startIcon={<EditIcon />}
                        onClick={handleEdit}
                        sx={{ mr: 2 }}
                    >
                        Edit
                    </Button>
                    <Button
                        variant="contained"
                        color="secondary"
                        onClick={handleCreateDeliveryNotes}
                    >
                        Create Delivery Notes
                    </Button>
                </Box>
            </Box>
            <Box sx={{ mb: 2 }}>
                <Typography variant="h6">Delivery Plan Number: {deliveryPlan.deliveryPlanNumber || 'N/A'}</Typography>
                <Typography variant="body1">Delivery Date: {formatDate(deliveryPlan.deliveryDate)}</Typography>
                <Typography variant="body1">
                    Status:{' '}
                    <Chip
                        label={deliveryPlan.status ? deliveryPlan.status.charAt(0).toUpperCase() + deliveryPlan.status.slice(1) : 'Draft'}
                        color={deliveryPlan.status === 'confirmed' ? 'success' : 'default'}
                        size="small"
                    />
                </Typography>
                <Divider sx={{ my: 2 }} />

                {/* Routes */}
                {deliveryPlan.routes.map((route) => {
                    const vehicle = vehicles.find((v) => v.id === route.vehicleId);
                    const driver = drivers.find((d) => d.id === route.driverId);

                    return (
                        <Accordion key={route.id}>
                            <AccordionSummary
                                expandIcon={<ExpandMoreIcon />}
                                aria-controls={`route-content-${route.id}`}
                                id={`route-header-${route.id}`}
                            >
                                <Typography variant="h6">{route.name}</Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                                <Typography variant="body1">
                                    <strong>Vehicle:</strong> {vehicle ? vehicle.name : 'Unassigned'}
                                </Typography>
                                <Typography variant="body1">
                                    <strong>Driver:</strong> {driver ? driver.name : 'Unassigned'}
                                </Typography>
                                <Typography variant="body1">
                                    <strong>Number of Orders:</strong> {route.salesOrders.length}
                                </Typography>
                                <Divider sx={{ my: 2 }} />
                                <List>
                                    {route.salesOrders.map((order) => {
                                        const salesOrder = salesOrders.find((so) => so.id === order.id);
                                        if (!salesOrder) return null; // Handle cases where sales order is not found
                                        return (
                                            <ListItemButton
                                                key={order.id}
                                                onClick={() => navigate(`/sales-process/sales-orders/detail/${salesOrder.id}`)}
                                                sx={{ pl: 0 }}
                                            >
                                                <ListItemText
                                                    primary={`Order Number: ${salesOrder.salesOrderNumber} - ${salesOrder.customerName}`}
                                                    secondary={`Items: ${salesOrder.summary.totalItem} - Total Amount: ${salesOrder.summary.totalAmount.toFixed(2)}`}
                                                />
                                            </ListItemButton>
                                        );
                                    })}
                                </List>
                            </AccordionDetails>
                        </Accordion>
                    );
                })}
            </Box>

            {/* DeliveryPlanPrintDialog */}
            <DeliveryPlanPrintDialog
                open={printDialogOpen}
                onClose={() => setPrintDialogOpen(false)}
                deliveryPlan={deliveryPlan}
                vehicles={vehicles}
                drivers={drivers}
                salesOrders={salesOrders}
            />

            {/* Confirmation Dialog */}
            <ConfirmationDialog
                isOpen={confirmationDialog.open}
                onClose={handleCloseConfirmationDialog}
                onConfirm={confirmCreateDeliveryNotes}
                text={confirmationDialog.text}
            />

            {/* Snackbar Alert */}
            <SnackbarAlert
                open={snackbar.open}
                onClose={handleCloseSnackbar}
                message={snackbar.message}
                severity={snackbar.severity}
            />
        </Paper>
    );
};

// Utility function to format date as 'YYYY-MM-DD'
const formatDate = (functionDate) => {
    let date = new Date(functionDate);

    if (!date || isNaN(date)) return '';
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');
    return `${year}-${month}-${day}`;
};

export default DeliveryPlanDetailsPage;
