import React, { useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { useSalesOrder } from './useSalesOrder';
import SalesOrderTable from './SalesOrderTable';
import SalesOrderAddress from './SalesOrderAddress';
import SnackbarAlert from '../../components/SnackbarAlert';
import { useAuth } from '../../context/AuthContext';
import SplitTransferButton from '../../components/SplitTransferButton';
import {
  TextField,
  MenuItem,
  Paper,
  Button,
  Grid,
  Typography,
  Tab,
  Tabs,
  Box,
  Chip,
} from '@mui/material';
import { Autocomplete } from '@mui/material';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { DatePicker } from '@mui/x-date-pickers';
import { useDateLocale } from '../../hooks/useDateLocale';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import WarningAmberIcon from '@mui/icons-material/WarningAmber';
import { useTranslation } from "react-i18next";
import LinearProgress from '@mui/material/LinearProgress';
import IconButton from '@mui/material/IconButton';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

const SalesOrderDetailPage = () => {
  const [snackbar, setSnackbar] = useState({
    open: false,
    message: '',
    severity: '',
  });
  const { apiKey, organizationId, token, userId } = useAuth();
  const navigate = useNavigate();
  const { salesOrderId } = useParams();
  const dateLocale = useDateLocale();
  const [selectedTab, setSelectedTab] = React.useState(0);
  const {
    salesOrderData,
    customersWithProducts,
    isCancelled,
    setSalesOrderData,
    loading,
    saveOrUpdateSalesOrder,
    removeRow,
    showAddRows,
    addProductRow,
    addMachineRow,
    addRow,
    addServiceRow,
    handleInputChange,
    handleCustomerChange,
    handleAddressChange,
    handleDownloadPdf,
    handleSendPdf,
    handleCancel,
    onTransfer,
  } = useSalesOrder(salesOrderId, apiKey, organizationId, token, userId);
  const { t } = useTranslation();

  const handleSnackbarClose = () => {
    setSnackbar({ ...snackbar, open: false });
  };

  const onSave = async () => {
    const isSuccess = await saveOrUpdateSalesOrder(setSnackbar);
    if (!isSuccess) {
      setSnackbar({ open: true, message: t("salesOrderDetailPage.saveFailed"), severity: 'error' });
    }
  };

  const downloadPdf = async () => {
    if (salesOrderId !== 'new') {
      await handleDownloadPdf(salesOrderId);
    } else {
      setSnackbar({ open: true, message: t("salesOrderDetailPage.saveBeforeDownload"), severity: 'warning' });
    }
  };

  const sendPDF = async () => {
    if (salesOrderId !== 'new') {
      await handleSendPdf(salesOrderId);
    } else {
      setSnackbar({ open: true, message: t("salesOrderDetailPage.saveBeforeSend"), severity: 'warning' });
    }
  };

  const handleTabChange = (event, newValue) => {
    setSelectedTab(newValue);
  };

  const renderCancellationStatus = () => {
    return isCancelled ? (
      <Chip
        icon={<WarningAmberIcon />}
        label={t("salesOrderDetailPage.cancelled")}
        color="warning"
        variant="outlined"
      />
    ) : null;
  };

  return (
    <Paper elevation={3} sx={{ mt: 4, p: 3 }}>
      <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={dateLocale}>
        {loading && <LinearProgress />}
        <Grid container spacing={2} alignItems="center" style={{ marginBottom: '20px' }}>
          <Grid item>
            <Typography variant="h4">
              <IconButton onClick={() => navigate(-1)} aria-label="back"><ArrowBackIcon /></IconButton>
              {salesOrderId === 'new' ? t('salesOrderDetailPage.createSalesOrder') : t('salesOrderDetailPage.editSalesOrder', { salesOrderNumber: salesOrderData.salesOrderNumber })}
            </Typography>
          </Grid>
          <Grid item>
            {renderCancellationStatus()}
          </Grid>
        </Grid>
        <Grid container spacing={3}>
          <Grid item xs={6}>
            <Autocomplete
              freeSolo
              options={customersWithProducts}
              getOptionLabel={(option) => option.firstname && option.surname ? `${option.firstname} ${option.surname}` : ''}
              isOptionEqualToValue={(option, value) => option.id === value.id}
              value={customersWithProducts.find(customer => customer.id === salesOrderData.customerId) || null}
              onChange={handleCustomerChange}
              renderInput={(params) => (
                <TextField {...params} label={t("salesOrderDetailPage.customerName")} fullWidth />
              )}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              select
              fullWidth
              name="recipientType"
              label={t("salesOrderDetailPage.recipientType")}
              variant="outlined"
              value={salesOrderData.recipientType}
              onChange={(e) => setSalesOrderData({ ...salesOrderData, recipientType: e.target.value })}
            >
              <MenuItem value="private">{t("salesOrderDetailPage.private")}</MenuItem>
              <MenuItem value="company">{t("salesOrderDetailPage.company")}</MenuItem>
            </TextField>
          </Grid>
          {/* Company Name Field - Conditionally Rendered */}
          {salesOrderData.recipientType === 'company' && (
            <Grid item xs={6}>
              <TextField
                fullWidth
                label={t("salesOrderDetailPage.companyName")}
                value={salesOrderData.companyName || ''}
                onChange={(e) => setSalesOrderData({ ...salesOrderData, companyName: e.target.value })}
              />
            </Grid>
          )}
          {salesOrderData.recipientType === 'company' && (
            <Grid item xs={6}>
              <TextField
                fullWidth
                label={t("salesOrderDetailPage.companyBRN")}
                value={salesOrderData.companyBRN || ''}
                onChange={(e) => setSalesOrderData({ ...salesOrderData, companyBRN: e.target.value })}
              />
            </Grid>
          )}
          {salesOrderId !== 'new' && (
            <Grid item xs={6}>
              <TextField
                fullWidth
                disabled
                name="salesOrderNumber"
                label={t("salesOrderDetailPage.salesOrderNumber")}
                variant="outlined"
                value={salesOrderData.salesOrderNumber}
                InputLabelProps={{ shrink: true }}
              />
            </Grid>
          )}
          <Grid item xs={6}>
            <DatePicker
              label={t("salesOrderDetailPage.orderDate")}
              value={salesOrderData.orderDate ? new Date(salesOrderData.orderDate) : null}
              onChange={(date) => setSalesOrderData({ ...salesOrderData, orderDate: date })}
              renderInput={(params) => <TextField {...params} fullWidth />}
            />
          </Grid>
          <Grid item xs={6}>
            <DatePicker
              label={t("salesOrderDetailPage.deliveryDate")}
              value={salesOrderData.deliveryDate ? new Date(salesOrderData.deliveryDate) : null}
              onChange={(date) => setSalesOrderData({ ...salesOrderData, deliveryDate: date })}
              renderInput={(params) => <TextField {...params} fullWidth />}
            />
          </Grid>
        </Grid>
        <Tabs value={selectedTab} onChange={handleTabChange} aria-label="sales order tabs">
          <Tab label={t("salesOrderDetailPage.salesOrderItems")} />
          <Tab label={t("salesOrderDetailPage.contactInformation")} />
        </Tabs>
        {selectedTab === 0 && (
          <Box sx={{ p: 3 }}>
            <Typography variant="h6">{t("salesOrderDetailPage.salesOrderItems")}</Typography>
            <SalesOrderTable
              salesOrderData={salesOrderData}
              handleInputChange={handleInputChange}
              removeRow={removeRow}
              addRow={addRow}
              showAddRows={showAddRows}
              addProductRow={addProductRow}
              addMachineRow={addMachineRow}
              addServiceRow={addServiceRow}
            />
            {/* ... */}
          </Box>
        )}
        {selectedTab === 1 && (
          <Box sx={{ p: 3 }}>
            <SalesOrderAddress
              salesOrderData={salesOrderData}
              handleInputChange={handleAddressChange} />
          </Box>
        )}
        <Grid container spacing={3}>
          <Grid item xs={9}>
            <Typography variant="h6">{t("salesOrderDetailPage.totalNetAmount")}</Typography>
            <Typography variant="subtitle1">{`€${salesOrderData.totalNetAmount}`}</Typography>
          </Grid>
          <Grid item xs={9}>
            <Typography variant="h6">{t("salesOrderDetailPage.taxAmount")}</Typography>
            <Typography variant="subtitle1">{`€${salesOrderData.taxAmount}`}</Typography>
          </Grid>
          <Grid item xs={9}>
            <Typography variant="h6">{t("salesOrderDetailPage.totalAmount")}</Typography>
            <Typography variant="subtitle1">{`€${salesOrderData.totalAmount}`}</Typography>
          </Grid>
        </Grid>
        <Grid container spacing={2}>
          <Grid item>
            <Button variant="contained" color="primary" onClick={onSave} disabled={isCancelled}>
              {t("salesOrderDetailPage.save")}
            </Button>
          </Grid>
          <Grid item>
            <SplitTransferButton
              documentId={salesOrderId}
              documentType="salesOrder"
              visibleOptions={['deliveryNote', 'invoice']}
              onTransfer={onTransfer}
              disabled={isCancelled || salesOrderId === 'new'}
            />
          </Grid>
          <Grid item>
            <Button variant="contained" color="secondary" onClick={downloadPdf} disabled={isCancelled || salesOrderId === 'new'}>
              {t("salesOrderDetailPage.downloadPDF")}
            </Button>
          </Grid>
          <Grid item>
            <Button variant="contained" color="secondary" onClick={sendPDF} disabled={isCancelled || salesOrderId === 'new'}>
              {t("salesOrderDetailPage.sendPDF")}
            </Button>
          </Grid>
          {salesOrderId !== 'new' && (
            <Grid item>
              <Button variant="contained" color="error" onClick={handleCancel} disabled={isCancelled}>
                {t("salesOrderDetailPage.cancel")}
              </Button>
            </Grid>
          )}
        </Grid>

        <SnackbarAlert
          open={snackbar.open}
          onClose={handleSnackbarClose}
          message={snackbar.message}
          severity={snackbar.severity}
        />
      </LocalizationProvider>
    </Paper>
  );
};

export default SalesOrderDetailPage;
