// src/pages/DeliveryPlanOverviewPage.js

import React, { useState, useEffect } from 'react';
import {
    Box,
    Button,
    Paper,
    Typography,
    LinearProgress,
    IconButton,
} from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import {
    ViewModule as ViewModuleIcon,
    ViewList as ViewListIcon,
    Visibility as EyeIcon,
    Edit as EditIcon,
    Delete as DeleteIcon,
} from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';
import GenericTableView from '../../components/GenericTableView';
import GenericCardView from '../../components/GenericCardView';
import SnackbarAlert from '../../components/SnackbarAlert';
import ConfirmationDialog from '../../components/ConfirmationDialog';
import { usePreferences } from '../../context/UserPreferencesContext';
import { useAuth } from '../../context/AuthContext';
import { fetchAllDeliveryPlans, deleteDeliveryPlan } from '../../services/DeliveryPlanService';
import { format } from 'date-fns';
import { useDateLocale } from '../../hooks/useDateLocale';


const DeliveryPlanOverviewPage = () => {
    const navigate = useNavigate();
    const { devicePreferences, updateDevicePreferences } = usePreferences();
    const dateLocale = useDateLocale();
    const { apiKey, organizationId, token: jwtToken } = useAuth();
    // State variables
    const [isLoading, setIsLoading] = useState(true);
    const [deliveryPlans, setDeliveryPlans] = useState([]);
    const [snackbar, setSnackbar] = useState({
        open: false,
        message: '',
        severity: '',
    });
    const [confirmDialogOpen, setConfirmDialogOpen] = useState(false);
    const [selectedPlanId, setSelectedPlanId] = useState(null);
    const [viewMode, setViewMode] = useState(
        devicePreferences.viewMode || 'table'
    );

    useEffect(() => {
        const fetchData = async () => {
            setIsLoading(true);
            try {
                const plans = await fetchAllDeliveryPlans(apiKey, organizationId, jwtToken);
                setDeliveryPlans(plans);
                setIsLoading(false);
            } catch (error) {
                console.error('Error fetching delivery plans:', error);
                setIsLoading(false);
            }
        };

        fetchData();
    }, [apiKey, organizationId, jwtToken]);

    const handleRowClick = (planId) => {
        navigate(`/delivery-plans/${planId}`);
    };

    const handleDeleteClick = (planId) => {
        setSelectedPlanId(planId);
        setConfirmDialogOpen(true);
    };

    const handleDeleteConfirmation = async () => {
        try {
            await deleteDeliveryPlan(selectedPlanId, apiKey, organizationId, jwtToken);
            setDeliveryPlans(deliveryPlans.filter((plan) => plan.id !== selectedPlanId));
            setSnackbar({
                open: true,
                message: 'Delivery plan deleted successfully.',
                severity: 'success',
            });
            setConfirmDialogOpen(false);
        } catch (error) {
            console.error('Error deleting delivery plan:', error);
            setSnackbar({
                open: true,
                message: 'Failed to delete delivery plan.',
                severity: 'error',
            });
            setConfirmDialogOpen(false);
        }
    };


    const toggleViewMode = () => {
        const newViewMode = viewMode === 'table' ? 'card' : 'table';
        setViewMode(newViewMode);
        updateDevicePreferences('viewMode', newViewMode);
    };

    if (isLoading) {
        return (
            <div>
                <Typography variant="h6">Loading delivery plans...</Typography>
                <LinearProgress />
            </div>
        );
    }

    // Structure for GenericTableView and GenericCardView
    const structure = {
        headers: [
            {
                key: 'deliveryPlanNumber',
                label: 'Plan Number',
                display: { value: true },
            },
            {
                key: 'deliveryDate',
                label: 'Date',
                display: { value: true },
            },
            {
                key: 'routeCount',
                label: 'Routes',
                display: { value: true },
            },
            {
                key: 'totalOrders',
                label: 'Total Orders',
                display: { value: true },
            },
        ],
        rows: deliveryPlans.map((plan) => {
            const routeCount = plan.routes.length;
            const totalOrders = plan.routes.reduce(
                (acc, route) => acc + route.salesOrders.length,
                0
            );
            return {
                id: plan.id,
                deliveryPlanNumber: { value: plan.deliveryPlanNumber },
                deliveryDate: { value: format(new Date(plan.deliveryDate), 'P', { locale: dateLocale }) },
                routeCount: { value: routeCount },
                totalOrders: { value: totalOrders },
                actions: [
                    {
                        icon: <EyeIcon />,
                        handler: () => navigate(`/delivery-plans/${plan.id}`),
                    },
                    {
                        icon: <EditIcon />,
                        handler: () => navigate(`/delivery-plans/edit/${plan.id}`),
                    },
                    {
                        icon: <DeleteIcon />,
                        handler: () => handleDeleteClick(plan.id),
                    },
                ],
            };
        }),
    };

    return (
        <Paper elevation={3} sx={{ mt: 4, p: 3 }}>
            <Typography variant="h4" gutterBottom>
                Delivery Plans
            </Typography>

            <Box
                display="flex"
                justifyContent="space-between"
                alignItems="center"
                sx={{ mt: 4, mb: 2 }}
            >
                <Button
                    variant="contained"
                    color="primary"
                    onClick={() => navigate('/delivery-plans/edit/new')}
                    startIcon={<AddIcon />}
                >
                    Add Delivery Plan
                </Button>

                <IconButton onClick={toggleViewMode} color="primary">
                    {viewMode === 'table' ? <ViewModuleIcon /> : <ViewListIcon />}
                </IconButton>
            </Box>

            {viewMode === 'table' ? (
                <GenericTableView structure={structure} onClick={handleRowClick} />
            ) : (
                <GenericCardView structure={structure} onClick={handleRowClick} />
            )}

            <SnackbarAlert
                open={snackbar.open}
                onClose={() => setSnackbar({ ...snackbar, open: false })}
                message={snackbar.message}
                severity={snackbar.severity}
            />

            <ConfirmationDialog
                isOpen={confirmDialogOpen}
                onClose={() => setConfirmDialogOpen(false)}
                onConfirm={handleDeleteConfirmation}
                text="Are you sure you want to delete this delivery plan?"
            />
        </Paper>
    );
};

export default DeliveryPlanOverviewPage;
