// DeliveryPlanEditPage.js (Complete with Both Option 1 and Option 2)
import React, { useState, useEffect, useRef } from 'react';
import {
    Box,
    Button,
    Paper,
    Typography,
    TextField,
    IconButton,
    LinearProgress,
    Chip, // Import Chip
} from '@mui/material';
import { useParams, useNavigate } from 'react-router-dom';
import { DatePicker } from '@mui/x-date-pickers';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import AddIcon from '@mui/icons-material/Add';
import { v4 as uuidv4 } from 'uuid';

import {
    DndContext,
    closestCenter,
    KeyboardSensor,
    PointerSensor,
    useSensor,
    useSensors,
    DragOverlay,
} from '@dnd-kit/core';
import {
    arrayMove,
    SortableContext,
    verticalListSortingStrategy,
    sortableKeyboardCoordinates,
} from '@dnd-kit/sortable';

import RouteComponent from './RouteComponent';
import SortableSalesOrderItem from './SortableSalesOrderItem';
import { useAuth } from '../../context/AuthContext';
import { fetchVehicles } from '../../services/VehicleService';
import { fetchDrivers } from '../../services/DriverService';
import { fetchSummarySalesOrders } from '../../services/SalesOrderService';
import { fetchDeliveryPlanById, saveDeliveryPlan, updateDeliveryPlan } from '../../services/DeliveryPlanService';

const DeliveryPlanEditPage = () => {
    const { id } = useParams();
    const navigate = useNavigate();
    const isEditMode = id !== 'new';
    const { apiKey, organizationId, token: jwtToken } = useAuth();

    const [deliveryPlan, setDeliveryPlan] = useState({
        id: '',
        deliveryDate: new Date(),
        routes: [],
        status: 'draft', // Set initial status to 'draft'
        deliveryPlanNumber: '', // Initialize deliveryPlanNumber
    });
    const [loading, setLoading] = useState(true);
    const [vehicles, setVehicles] = useState([]);
    const [drivers, setDrivers] = useState([]);
    const [salesOrders, setSalesOrders] = useState([]);

    const [itemsByContainer, setItemsByContainer] = useState({});
    const [activeId, setActiveId] = useState(null);

    const unassignedOrdersRef = useRef(null); // Reference to the unassigned orders list

    useEffect(() => {
        const loadData = async () => {
            setLoading(true);
            try {
                const [vehiclesData, driversData] = await Promise.all([
                    fetchVehicles(apiKey, organizationId, jwtToken),
                    fetchDrivers(apiKey, organizationId, jwtToken),
                ]);

                setVehicles(vehiclesData);
                setDrivers(driversData);

                // Define filters for fetching sales orders
                const filters = {
                    status: ['approved', 'draft'],
                };

                // Fetch summarized sales orders
                const salesOrdersData = await fetchSummarySalesOrders(apiKey, organizationId, jwtToken, filters);

                setSalesOrders(salesOrdersData);

                let initialDeliveryPlan = deliveryPlan;

                if (isEditMode) {
                    const existingPlan = await fetchDeliveryPlanById(apiKey, organizationId, jwtToken, id);
                    if (existingPlan) {
                        initialDeliveryPlan = existingPlan;
                        setDeliveryPlan(existingPlan);
                    }
                }

                // Initialize itemsByContainer
                const initialItemsByContainer = {};

                // Collect assigned order IDs from routes
                let assignedOrderIds = [];
                initialDeliveryPlan.routes.forEach((route) => {
                    assignedOrderIds = assignedOrderIds.concat(route.salesOrders.map(order => order.id));
                });

                // Unassigned orders
                const unassignedOrders = salesOrdersData.filter(
                    (order) => !assignedOrderIds.includes(order.id)
                );

                initialItemsByContainer['unassigned'] = unassignedOrders.map((order) => order.id);

                // Routes
                initialDeliveryPlan.routes.forEach((route) => {
                    initialItemsByContainer[route.id] = route.salesOrders.map((order) => order.id);
                });

                setItemsByContainer(initialItemsByContainer);

            } catch (error) {
                console.error('Error loading data:', error);
            } finally {
                setLoading(false);
            }
        };

        loadData();
    }, [isEditMode, id, apiKey, organizationId, jwtToken]);


    function useWindowHeight() {
        const [height, setHeight] = useState(window.innerHeight);

        useEffect(() => {
            const handleResize = () => setHeight(window.innerHeight);

            window.addEventListener('resize', handleResize);
            return () => window.removeEventListener('resize', handleResize);
        }, []);

        return height;
    }
    const windowHeight = useWindowHeight(); // Get the current window height
    const availableHeight = windowHeight - 360;

    // Utility function to format date as 'YYYY-MM-DD'
    const formatDate = (functionDate) => {
        let date = new Date(functionDate);

        if (!date) return '';
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const day = String(date.getDate()).padStart(2, '0');
        return `${year}-${month}-${day}`;
    };

    const handleDateChange = (date) => {
        setDeliveryPlan((prevPlan) => ({
            ...prevPlan,
            deliveryDate: date,
        }));
        // Scroll to the selected date in the unassigned orders list
        setTimeout(() => {
            scrollToDate(date);
        }, 100);
    };

    const scrollToDate = (date) => {
        if (unassignedOrdersRef.current) {
            const dateString = formatDate(date);
            const dateSection = document.getElementById(`date-section-${dateString}`);
            if (dateSection) {
                dateSection.scrollIntoView({ behavior: 'smooth', block: 'start' });
            }
        }
    };

    const handleAddRoute = () => {
        const newRouteId = `route${deliveryPlan.routes.length + 1}`;
        const newRoute = {
            id: uuidv4(),
            vehicleId: '',
            driverId: '',
            name: `Route ${newRouteId}`,
            salesOrders: [],
        };
        setDeliveryPlan((prevPlan) => ({
            ...prevPlan,
            routes: [...prevPlan.routes, newRoute],
        }));

        setItemsByContainer((prevItems) => ({
            ...prevItems,
            [newRoute.id]: [],
        }));
    };

    const handleRemoveRoute = (routeId) => {
        setDeliveryPlan((prevPlan) => ({
            ...prevPlan,
            routes: prevPlan.routes.filter((route) => route.id !== routeId),
        }));

        setItemsByContainer((prevItems) => {
            const newItems = { ...prevItems };
            const routeOrderIds = newItems[routeId];
            newItems['unassigned'] = [...newItems['unassigned'], ...routeOrderIds];
            delete newItems[routeId];
            return newItems;
        });
    };

    const handleRouteChange = (updatedRoute) => {
        setDeliveryPlan((prevPlan) => ({
            ...prevPlan,
            routes: prevPlan.routes.map((route) =>
                route.id === updatedRoute.id ? updatedRoute : route
            ),
        }));
    };

    const handleSave = async () => {
        const updatedRoutes = deliveryPlan.routes.map((route) => {
            const orderIds = itemsByContainer[route.id] || [];
            return {
                ...route,
                salesOrders: orderIds.map((orderId) => ({ id: orderId })), // Send sales order IDs
            };
        });

        const updatedDeliveryPlan = {
            ...deliveryPlan,
            routes: updatedRoutes,
        };

        try {
            if (isEditMode) {
                await updateDeliveryPlan(updatedDeliveryPlan, apiKey, organizationId, jwtToken);
                console.log('Delivery plan updated successfully.');
            } else {
                await saveDeliveryPlan(updatedDeliveryPlan, apiKey, organizationId, jwtToken);
                console.log('Delivery plan created successfully.');
            }
            navigate('/delivery-plans');
        } catch (error) {
            console.error('Error saving delivery plan:', error);
            // Handle error (e.g., show snackbar)
        }
    };


    // Sensors for drag-and-drop
    const sensors = useSensors(
        useSensor(PointerSensor),
        useSensor(KeyboardSensor, {
            coordinateGetter: sortableKeyboardCoordinates,
        })
    );

    const handleDragStart = (event) => {
        const { active } = event;
        setActiveId(active.id);
    };

    const handleDragEnd = (event) => {
        const { active, over } = event;

        if (!over) {
            setActiveId(null);
            return;
        }

        const activeContainer = findContainer(active.id);
        const overContainer = findContainer(over.id);

        if (!activeContainer || !overContainer) {
            setActiveId(null);
            return;
        }

        if (activeContainer === overContainer) {
            // Reordering within the same container (route or unassigned)
            const items = itemsByContainer[activeContainer];
            const oldIndex = items.indexOf(active.id);
            const newIndex = items.indexOf(over.id);

            const newItems = arrayMove(items, oldIndex, newIndex);

            // Update the itemsByContainer state
            setItemsByContainer((prev) => ({
                ...prev,
                [activeContainer]: newItems,
            }));

            // Optionally assign positions
            setDeliveryPlan((prevPlan) => {
                const updatedRoutes = prevPlan.routes.map((route) => {
                    if (route.id === activeContainer) {
                        const updatedSalesOrders = newItems.map((id, index) => ({
                            ...salesOrders.find((order) => order.id === id),
                            position: index + 1,
                        }));
                        return { ...route, salesOrders: updatedSalesOrders };
                    }
                    return route;
                });

                // Handle 'unassigned' container if needed
                if (activeContainer === 'unassigned') {
                    return { ...prevPlan };
                }

                return { ...prevPlan, routes: updatedRoutes };
            });
        } else {
            // Moving between containers
            if (activeContainer === 'unassigned' && overContainer !== 'unassigned') {
                setItemsByContainer((prev) => {
                    const activeItems = prev[activeContainer].filter((id) => id !== active.id);
                    const overItems = prev[overContainer];
                    const newOverItems = [...overItems, active.id];

                    return {
                        ...prev,
                        [activeContainer]: activeItems,
                        [overContainer]: newOverItems,
                    };
                });

                // Optionally assign positions
                setDeliveryPlan((prevPlan) => {
                    const updatedRoutes = prevPlan.routes.map((route) => {
                        if (route.id === overContainer) {
                            const updatedSalesOrders = [
                                ...route.salesOrders,
                                salesOrders.find((order) => order.id === active.id),
                            ].map((order, index) => ({
                                ...order,
                                position: index + 1,
                            }));
                            return { ...route, salesOrders: updatedSalesOrders };
                        }
                        return route;
                    });

                    return { ...prevPlan, routes: updatedRoutes };
                });
            }
            // Optionally handle other container movements if necessary
        }

        setActiveId(null);
    };


    const findContainer = (id) => {
        if (id in itemsByContainer) {
            return id;
        }

        return Object.keys(itemsByContainer).find((key) =>
            itemsByContainer[key].includes(id)
        );
    };

    const handleRemoveOrder = (orderId, containerId) => {
        setItemsByContainer((prev) => {
            const containerItems = prev[containerId].filter((id) => id !== orderId);
            const unassignedItems = [...prev['unassigned'], orderId];

            return {
                ...prev,
                [containerId]: containerItems,
                unassigned: unassignedItems,
            };
        });
    };

    useEffect(() => {
        // Scroll to the selected date when component mounts or date changes
        scrollToDate(deliveryPlan.deliveryDate);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [deliveryPlan.deliveryDate]);

    if (loading) {
        if (isEditMode && !deliveryPlan.id) {
            return (
                <Box
                    display="flex"
                    flexDirection="column"
                    alignItems="center"
                    minHeight="100vh"
                >
                    <Typography variant="h6">Loading delivery plan data...</Typography>
                    <LinearProgress sx={{ width: '100%', mt: 2 }} />
                </Box>
            );
        } else {
            return (
                <Box
                    display="flex"
                    flexDirection="column"
                    alignItems="center"
                    minHeight="100vh"
                >
                    <Typography variant="h6">Loading data...</Typography>
                    <LinearProgress sx={{ width: '100%', mt: 2 }} />
                </Box>
            );
        }
    }

    const unassignedOrderIds = itemsByContainer['unassigned'] || [];
    const unassignedOrders = salesOrders.filter((order) =>
        unassignedOrderIds.includes(order.id)
    );

    // Group unassigned orders by delivery date (assuming each order has a 'deliveryDate' property)
    const ordersByDate = unassignedOrders.reduce((acc, order) => {
        let date = order.deliveryDate;
        if (typeof date === 'string') {
            // Convert string to Date object if necessary
            date = new Date(date);
        }
        const dateString = formatDate(date);
        if (!acc[dateString]) {
            acc[dateString] = [];
        }
        acc[dateString].push(order);
        return acc;
    }, {});

    return (
        <Paper elevation={3} sx={{ mt: 4, p: 3 }}>
            <Typography variant="h4" gutterBottom>
                <IconButton onClick={() => navigate(-1)} aria-label="back">
                    <ArrowBackIcon />
                </IconButton>
                {isEditMode ? 'Edit Delivery Plan' : 'Create Delivery Plan'}
            </Typography>
            <Box sx={{ mt: 4 }}>
                {/* Display Delivery Plan Number and Status */}
                <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
                    <Typography variant="h6" sx={{ mr: 2 }}>
                        Delivery Plan Number: {deliveryPlan.deliveryPlanNumber || 'To be assigned'}
                    </Typography>
                    <Chip
                        label={deliveryPlan.status ? deliveryPlan.status.charAt(0).toUpperCase() + deliveryPlan.status.slice(1) : 'Draft'}
                        color={deliveryPlan.status === 'confirmed' ? 'success' : 'default'}
                    />
                </Box>

                {/* Flex container for DatePicker and Add Route Button */}
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: { xs: 'column', sm: 'row' }, // Responsive layout
                        alignItems: { sm: 'center' },
                        justifyContent: 'space-between',
                        gap: 2,
                        mb: 4,
                    }}
                >
                    <DatePicker
                        label="Delivery Date"
                        value={new Date(deliveryPlan.deliveryDate)}
                        onChange={handleDateChange}
                        renderInput={(params) => <TextField {...params} fullWidth />}
                    />
                    <Button
                        variant="contained"
                        color="primary"
                        startIcon={<AddIcon />}
                        onClick={handleAddRoute}
                        sx={{ minWidth: '150px' }} // Optional: Set a minimum width
                    >
                        Add Route
                    </Button>
                    <Button variant="contained" color="primary" onClick={handleSave}>
                        {isEditMode ? 'Update Delivery Plan' : 'Create Delivery Plan'}
                    </Button>
                </Box>

                <DndContext
                    sensors={sensors}
                    collisionDetection={closestCenter}
                    onDragStart={handleDragStart}
                    onDragEnd={handleDragEnd}
                >
                    <Box sx={{ display: 'flex', gap: 2 }}>
                        {/* Unassigned Orders List */}
                        <Box
                            sx={{
                                flex: 1,
                                maxWidth: '300px',
                                overflowY: 'auto',
                                maxHeight: availableHeight, // Use dynamic available height
                            }}
                            ref={unassignedOrdersRef}
                        >
                            <Typography variant="h5">Unassigned Sales Orders</Typography>
                            <SortableContext
                                id="unassigned"
                                items={unassignedOrderIds}
                                strategy={verticalListSortingStrategy}
                            >
                                {Object.keys(ordersByDate).map((date) => (
                                    <Box
                                        key={date}
                                        id={`date-section-${date}`} // Assign standardized ID
                                        sx={{ mt: 2 }}
                                    >
                                        <Typography variant="subtitle1">{date}</Typography>
                                        {ordersByDate[date].map((order) => (
                                            <SortableSalesOrderItem
                                                key={order.id}
                                                id={order.id}
                                                salesOrder={order}
                                            />
                                        ))}
                                    </Box>
                                ))}
                            </SortableContext>
                        </Box>

                        {/* Routes */}
                        <Box
                            sx={{
                                flex: 2,
                                maxHeight: availableHeight, // Use dynamic available height
                                overflowY: 'auto',
                            }}
                        >
                            {/* Routes remain unchanged */}
                            {deliveryPlan.routes.map((route) => (
                                <RouteComponent
                                    key={route.id}
                                    route={route}
                                    vehicles={vehicles}
                                    drivers={drivers}
                                    salesOrders={salesOrders}
                                    items={itemsByContainer[route.id] || []}
                                    onRouteChange={handleRouteChange}
                                    onRemoveRoute={handleRemoveRoute}
                                    handleRemoveOrder={handleRemoveOrder}
                                    activeId={activeId}
                                />
                            ))}
                        </Box>
                    </Box>

                    <DragOverlay>
                        {activeId ? (
                            <SortableSalesOrderItem
                                id={activeId}
                                salesOrder={salesOrders.find((order) => order.id === activeId)}
                                dragOverlay
                            />
                        ) : null}
                    </DragOverlay>
                </DndContext>
            </Box>
        </Paper>
    );
};

export default DeliveryPlanEditPage;
