import React, { useState, useEffect } from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions, TextField, Button, LinearProgress } from '@mui/material';
import { fetchVehicleById, addVehicle, updateVehicle } from '../../services/VehicleService';
import { useAuth } from '../../context/AuthContext';

const VehicleDialog = ({ open, onClose, vehicleId = null, initialData = null, onSave }) => {
    const { apiKey, organizationId, token } = useAuth();
    const [vehicleData, setVehicleData] = useState(initialData || { make: '', licensePlate: '', capacity: '', model: '', name: '' });
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        if (vehicleId && !initialData) {
            loadVehicleData(vehicleId);
        }
    }, [vehicleId, initialData]);

    const loadVehicleData = async (id) => {
        setLoading(true);
        try {
            const data = await fetchVehicleById(apiKey, organizationId, token, id);
            setVehicleData(data);
        } catch (error) {
            console.error('Error fetching vehicle:', error);
        } finally {
            setLoading(false);
        }
    };

    const handleSave = async () => {
        setLoading(true);
        try {
            if (vehicleId) {
                await updateVehicle(vehicleData, apiKey, organizationId, token);
            } else {
                await addVehicle(vehicleData, apiKey, organizationId, token);
            }
            onSave(); // Callback to refresh the parent list or indicate save success
            onClose();
        } catch (error) {
            console.error('Error saving vehicle:', error);
        } finally {
            setLoading(false);
        }
    };

    return (
        <Dialog open={open} onClose={onClose} fullWidth>
            <DialogTitle>{vehicleId ? 'Edit Vehicle' : 'Add Vehicle'}</DialogTitle>
            {loading && <LinearProgress />}
            <DialogContent>
                <TextField
                    margin="normal"
                    fullWidth
                    label="Make"
                    value={vehicleData.make}
                    onChange={(e) => setVehicleData({ ...vehicleData, make: e.target.value })}
                    disabled={loading}
                />
                <TextField
                    margin="normal"
                    fullWidth
                    label="Model"
                    value={vehicleData.model}
                    onChange={(e) => setVehicleData({ ...vehicleData, model: e.target.value })}
                    disabled={loading}
                />
                <TextField
                    margin="normal"
                    fullWidth
                    label="Name"
                    value={vehicleData.name}
                    onChange={(e) => setVehicleData({ ...vehicleData, name: e.target.value })}
                    disabled={loading}
                />
                <TextField
                    margin="normal"
                    fullWidth
                    label="License Plate"
                    value={vehicleData.licensePlate}
                    onChange={(e) => setVehicleData({ ...vehicleData, licensePlate: e.target.value })}
                    disabled={loading}
                />
                <TextField
                    margin="normal"
                    fullWidth
                    label="Capacity"
                    value={vehicleData.capacity}
                    onChange={(e) => setVehicleData({ ...vehicleData, capacity: e.target.value })}
                    disabled={loading}
                />
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose} disabled={loading}>Cancel</Button>
                <Button variant="contained" onClick={handleSave} disabled={loading}>
                    {vehicleId ? 'Save Changes' : 'Create Vehicle'}
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default VehicleDialog;
