import React, { useState, useEffect } from 'react';
import {
    Dialog,
    DialogContent,
    DialogActions,
    DialogTitle,
    Button,
    Typography,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
    IconButton,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close'; // Import Close Icon
import '../../styles/printStyles.css'; // Ensure this CSS file exists

const DeliveryPlanPrintDialog = ({ open, onClose, deliveryPlan, vehicles, drivers }) => {
    const [currentDate, setCurrentDate] = useState('');
    const [printing, setPrinting] = useState(false);

    const handlePrint = async () => {
        try {
            const date = new Date().toLocaleDateString('en-US');
            setCurrentDate(date);
            setPrinting(true);
        } catch (error) {
            console.error('Error fetching current date:', error);
        }
    };

    useEffect(() => {
        if (printing && currentDate) {
            window.print();
            setPrinting(false);
        }
    }, [printing, currentDate]);

    const formatDate = (functionDate) => {
        let date = new Date(functionDate);
        if (isNaN(date)) return '';
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const day = String(date.getDate()).padStart(2, '0');
        return `${year}-${month}-${day}`;
    };

    return (
        <Dialog
            open={open}
            onClose={onClose}
            maxWidth="md" // Set maximum width (e.g., 'sm', 'md', 'lg', etc.)
            fullWidth // Ensures the dialog takes full width up to the maxWidth
            aria-labelledby="delivery-plan-dialog-title"
        // Optional: Add custom styles or classes for additional spacing
        >
            <DialogTitle
                id="delivery-plan-dialog-title"
                sx={{ m: 0, p: 2, display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}
            >
                <Typography variant="h6">Delivery Plan Details</Typography>
                <IconButton
                    aria-label="close"
                    onClick={onClose}
                    sx={{
                        color: (theme) => theme.palette.grey[500],
                    }}
                >
                    <CloseIcon />
                </IconButton>
            </DialogTitle>
            <DialogContent dividers>
                <div id="print-content">
                    {deliveryPlan.routes.map((route, index) => {
                        const vehicle = vehicles.find((v) => v.id === route.vehicleId);
                        const driver = drivers.find((d) => d.id === route.driverId);

                        return (
                            <div key={route.id} className={index > 0 ? 'pagebreak' : ''}>
                                {/* Header Section */}
                                <div className="header-section" style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '16px' }}>
                                    {/* Route Information on the left */}
                                    <div style={{ flex: 1 }}>
                                        <Typography variant="h5" gutterBottom>
                                            {route.name}
                                        </Typography>
                                        <Typography variant="body1">
                                            <strong>Vehicle:</strong> {vehicle ? vehicle.name : 'Unassigned'}
                                        </Typography>
                                        <Typography variant="body1" gutterBottom>
                                            <strong>Driver:</strong> {driver ? driver.name : 'Unassigned'}
                                        </Typography>
                                    </div>

                                    {/* Plan Information on the right */}
                                    <div style={{ flex: 1, textAlign: 'right' }}>
                                        <Typography variant="h6" gutterBottom>
                                            Delivery Plan Number: {deliveryPlan.deliveryPlanNumber || 'N/A'}
                                        </Typography>
                                        <Typography variant="body1" gutterBottom>
                                            Delivery Date: {formatDate(deliveryPlan.deliveryDate)}
                                        </Typography>
                                        <Typography variant="body1" gutterBottom>
                                            Date: {currentDate}
                                        </Typography>
                                    </div>
                                </div>

                                {/* Table Section */}
                                <TableContainer component={Paper} className="table-container" style={{ marginBottom: '24px' }}>
                                    <Table>
                                        <TableHead>
                                            <TableRow>
                                                <TableCell><strong>Customer Name</strong></TableCell>
                                                <TableCell><strong>Address</strong></TableCell>
                                                <TableCell><strong>Items</strong></TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {route.salesOrders.map((order) => (
                                                <TableRow key={order.id}>
                                                    <TableCell>{order.customerName}</TableCell>
                                                    <TableCell>{order.address?.street}, {order.address?.city}</TableCell>
                                                    <TableCell>
                                                        {order.rows.map((row, idx) => (
                                                            <div key={row.rowKey || idx}>
                                                                {row.description} - <strong>Qty:</strong> {row.quantity}
                                                            </div>
                                                        ))}
                                                    </TableCell>
                                                </TableRow>
                                            ))}
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            </div>
                        );
                    })}
                </div>
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose}>Close</Button>
                <Button onClick={handlePrint} variant="contained" color="primary">
                    Print
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default DeliveryPlanPrintDialog;
